<template>
  <div class=" auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <div
        class="d-flex align-center pa-2"
        style="background-color: black"
      >
        <div>
          <router-link
            to="/"
            class="m-2"
          >
            <img
              height="auto"
              width="100"
              :src="appLogo"
            />
          </router-link>
        </div>
        <h2 class="ml-auto mr-auto text-uppercase header">
          {{ getHeaderText() }}
        </h2>
        <!--        <div style="width: 20%">-->
        <!--        </div>-->
      </div>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          class="d-flex align-center auth-bg pt-4"
        >
          <v-row class="">
            <v-col
              class="mx-auto"
            >
              <!-- login form -->
              <v-card-text>
                <registration-steps
                  :type="type"
                ></registration-steps>
              </v-card-text>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline, mdiArrowRightDropCircleOutline } from '@mdi/js'
import themeConfig from '@themeConfig'

// import useAppConfig from '@core/@app-config/useAppConfig'
import RegistrationSteps from '@/views/registration/registrationSteps.vue'
import router from '@/router'
import assistanceService from '@/services/assistanceService'

export default {
  components: {
    RegistrationSteps,
  },
  setup() {
    // const { isDark } = useAppConfig()
    // isDark.value = false

    const {
      getHeaderText,
    } = assistanceService()

    return {
      getHeaderText,
      type: router.currentRoute.params.type,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiArrowRightDropCircleOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
