import { PROVINCE } from 'codice-fiscale-js/src/lista-province'
import { COMUNI } from 'codice-fiscale-js/src/lista-comuni'
import { ref } from '@vue/composition-api'
import CodiceFiscale from 'codice-fiscale-js'
import store from '@/store'

export default () => {
  // Data
  const userData = ref({
    type: '',
    codiceSoggetto: '',

    // Step 1
    // Privati
    firstName: '',
    lastName: '',
    gender: '',
    birthday: '',
    province: '',
    city: '',
    fiscalCode: '',
    telephone: '',

    // Saloni
    vat: '',
    businessName: '',

    // Step 2
    addressCo: '',
    addressFirstName: '',
    addressLastName: '',
    addressStreet: '',
    addressNumber: '',
    addressZip: '',
    addressCity: '',
    addressProvince: '',
    addressPhone: '',
    addressNote: '',

    // Step 3
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',

    privacy: false,
  })

  const province = ref([])
  const comuni = ref([])

  const generateProvincia = () => {
    const provinceValues = Object.values(PROVINCE)
    const provinceKeys = Object.keys(PROVINCE)
    let index = 0
    provinceKeys.forEach(provinciaCode => {
      if (provinciaCode !== 'EE') {
        province.value[index] = {
          label: provinceValues[index].toUpperCase(),
          value: provinciaCode,
        }
      }

      index += 1
    })

    province.value.sort((a, b) => a.label.localeCompare(b.label))
    province.value.unshift({
      label: 'ESTERO',
      value: 'EE',
    })
  }

  const generateComune = () => {
    const comuniList = COMUNI
    let index = 0
    comuniList.forEach(comune => {
      comuni.value[index] = {
        province: comune[1],
        label: comune[2],
        value: comune[2],
        code: comune[0],
      }
      index += 1
    })

    comuni.value.sort((a, b) => a.label.localeCompare(b.label))
  }

  const filterCityByProvince = selectedProvince => comuni.value.filter(comune => comune.province === selectedProvince)

  const generateFiscalCode = () => {
    const data = userData.value
    if (data.firstName && data.lastName && data.gender && data.birthday && data.province && data.city) {
      const birthday = data.birthday.split('-')
      const cf = new CodiceFiscale({
        name: data.firstName,
        surname: data.lastName,
        gender: data.gender,
        day: birthday[2],
        month: birthday[1],
        year: birthday[0],
        birthplace: data.city.code,

        // birthplaceProvincia: data.province, // Optional
      })

      userData.value.fiscalCode = cf.code
      userData.value.city = userData.value.city.value
    }
  }

  const handleAddressStreet = addressStreetToHandle => {
    const firstNumber = addressStreetToHandle.match(/\d/)
    const positionOfFirstNumber = firstNumber ? firstNumber.index : null
    let addressStreetNumber = ''

    // la assegno uguale all'inidirizzo originale all'inizio perché alcuni indirizzi non hanno il numero
    let addressStreet = addressStreetToHandle
    if (positionOfFirstNumber) {
      addressStreetNumber = addressStreetToHandle.substr(positionOfFirstNumber)
      addressStreet = addressStreetToHandle.substr(0, positionOfFirstNumber).trim()
    }

    return {
      street: addressStreet,
      number: addressStreetNumber,
    }
  }

  const fetchAddresses = () => store.dispatch('user/fetchAddresses', {
    partitaiva: userData.value.vat,
  })
    .catch(error => {
      console.log(error)
    })

  const getAddresses = () => store.getters['user/getAddresses']

  return {
    userData,
    province,
    comuni,

    generateProvincia,
    generateComune,
    filterCityByProvince,
    generateFiscalCode,
    handleAddressStreet,

    fetchAddresses,
    getAddresses,
  }
}
