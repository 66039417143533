<template>
  <div>
    <div class="text-center text-black text-medium mb-8">
      {{ getSubHeaderText() }}
    </div>
    <v-stepper
      v-model="activeStep"
      class="custom-header"
    >
      <!-- Header -->
      <v-stepper-header>
        <!-- Header: Step 1 -->
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
          :rules="[() => stepValidationResult[1]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold me-3 ghd-ITCAvant"
              :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
            >01</span>
            <div class="d-flex flex-column">
              <span
                class="text-sm font-weight-semibold"
                :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
              >Informazioni</span>
            </div>
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- Header: Step 2 -->
        <v-stepper-step
          :complete="activeStep > 2"
          step="2"
          :rules="[() => stepValidationResult[2]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold me-3 ghd-ITCAvant"
              :class="stepValidationResult[2] ? 'text--primary' : 'error--text'"
            >02</span>
            <div class="d-flex flex-column">
              <span
                class="text-sm font-weight-semibold"
                :class="stepValidationResult[2] ? 'text--primary' : 'error--text'"
              >Indirizzo</span>
            </div>
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- Header: Step 3 -->
        <v-stepper-step
          step="3"
          :rules="[() => stepValidationResult[3]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold me-3 ghd-ITCAvant"
              :class="stepValidationResult[3] ? 'text--primary' : 'error--text'"
            >03</span>
            <div class="d-flex flex-column">
              <span
                class="text-sm font-weight-semibold "
                :class="stepValidationResult[3] ? 'text--primary' : 'error--text'"
              >Dati di accesso</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content step="1">
          <v-form
            ref="step1Form"
            class="pb-6 pt-2"
          >
            <v-row v-if="userData.type === PRIVATO">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="userData.firstName"
                  label="Nome"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[validators.required, validators.alphaWithSpacesValidator]"
                  placeholder="Nome"
                  @keyup="userData.firstName = userData.firstName.toUpperCase()"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="userData.lastName"
                  label="Cognome"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[validators.required, validators.alphaWithSpacesValidator]"
                  placeholder="Cognome"
                  @keyup="userData.lastName = userData.lastName.toUpperCase()"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="userData.gender"
                  :items="['M', 'F']"
                  hide-details="auto"
                  label="Genere"
                  outlined
                  dense
                  :rules="[validators.required]"
                  @keyup="userData.gender = userData.gender.toUpperCase()"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-menu
                  ref="menuref"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="userData.birthday"
                      label="Data di nascita"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      :rules="[validators.required]"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    ref="picker"
                    v-model="userData.birthday"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1900-01-01"
                    color="primary"
                    locale="it-IT"
                    :first-day-of-week="1"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="userData.province"
                  :items="province"
                  item-text="label"
                  item-value="value"
                  hide-details="auto"
                  label="Provincia di nascita"
                  outlined
                  dense
                  :rules="[validators.required]"
                  @change="userData.city = ''"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="userData.city"
                  :items="filterCityByProvince(userData.province)"
                  item-text="label"
                  hide-details="auto"
                  label="Comune di nascita"
                  outlined
                  dense
                  return-object
                  :rules="[validators.required(userData.city.value ? userData.city.value : userData.city)]"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="userData.fiscalCode"
                  label="Codice Fiscale"
                  outlined
                  dense
                  readonly
                  hide-details="auto"
                  placeholder="Codice Fiscale"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="userData.telephone"
                  label="Telefono"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[validators.required, validators.integerValidator]"
                  placeholder="Telefono"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-else-if="userData.type === SALONE">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="userData.vat"
                  label="Partita IVA"
                  :disabled="isDisabledPIvaField"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[validators.required, validators.integerValidator, validators.pIvaValidator(userData.vat, 11, 5)]"
                  placeholder="Partita IVA"
                  @keyup="userData.vat = userData.vat.toUpperCase()"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="userData.telephone"
                  label="Telefono"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[validators.required, validators.integerValidator]"
                  placeholder="Telefono"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-btn
                  v-if="(userData.vat.length === 5 || userData.vat.length === 11) && !userData.codiceSoggetto"
                  color="primary"
                  dark
                  @click="findAddresses"
                >
                  Ricerca indirizzi
                  <v-icon
                    dark
                    right
                  >
                    {{ mdiMagnify }}
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col cols="12">
                <div
                  v-if="addresses && addresses.length && addressesSearched"
                >
                  <v-row>
                    <v-col
                      v-for="(address, index) in addresses"
                      :key="index"
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <v-card
                        :style="`border: 1px solid ${selectedAddress && selectedAddress.index === address.index ? theme.themes.light.primary : 'black'}; background-color: ${resolveBackgroundAddress(address.available).color}`"
                        @click="selectAddressCard(address, index)"
                      >
                        <v-card-title>
                          <span class="ghd-ITCAvant text-black">{{ address.RagioneSociale }}</span>
                        </v-card-title>

                        <v-card-text>
                          {{ address.Indirizzo }} {{ address.Localita }} {{ address.Cap }}
                          {{ address.Provincia }} {{ address.Nazione }}
                        </v-card-text>
                        <v-card-subtitle v-if="!address.available">
                          <div class="ghd-ITCAvant text-black text-uppercase">
                            <u>Account gia registrato con questo indirizzo. Effettua il login!</u>
                          </div>
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-else-if="addressesSearched && !addresses.length"
                >
                  <v-col
                    cols="12"
                    class="mb-5 text-medium text-uppercase text-black text-bolder ghd-ITCAvant"
                  >
                    Nessuna anagrafica disponibile trovata
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              class="me-2"
              @click="handleStepsProgress(1)"
            >
              Prossimo
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Stepper Content: Step 2 -->
        <v-stepper-content step="2">
          <v-form
            ref="step2Form"
            class="pb-6 pt-2 multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="userData.addressFirstName"
                  :label="userData.type === SALONE ? 'Ragione sociale' : 'Nome' "

                  outlined
                  dense
                  hide-details="auto"
                  :rules="[validators.required]"
                  placeholder="Nome"
                  :disabled="userData.type === SALONE"
                  @keyup="userData.addressFirstName = userData.addressFirstName.toUpperCase()"
                ></v-text-field>
              </v-col>

              <v-col
                v-if="userData.type === PRIVATO"
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="userData.addressLastName"
                  label="Cognome"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[validators.required, validators.alphaWithSpacesValidator]"
                  placeholder="Cognome"
                  @keyup="userData.addressLastName = userData.addressLastName.toUpperCase()"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="userData.addressStreet"
                  label="Indirizzo"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[validators.required, validators.alphaWithSpacesValidator]"
                  placeholder="Indirizzo"
                  :disabled="userData.type === SALONE"
                  @keyup="userData.addressStreet = userData.addressStreet.toUpperCase()"
                ></v-text-field>
              </v-col>

              <v-col
                v-if="userData.type === PRIVATO"
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="userData.addressNumber"
                  label="Numero Civico"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[validators.required, validators.alphaSlashValidator, validators.maxlenght(userData.addressNumber, 10)]"
                  placeholder="Numero Civico"
                  @keyup="userData.addressNumber = userData.addressNumber.toUpperCase()"
                ></v-text-field>
              </v-col>
              <!--              <v-col-->
              <!--                cols="12"-->
              <!--                md="4"-->
              <!--              >-->
              <!--                <v-text-field-->
              <!--                  v-model="userData.addressCo"-->
              <!--                  label="Presso"-->
              <!--                  outlined-->
              <!--                  dense-->
              <!--                  hide-details="auto"-->
              <!--                  placeholder="Presso"-->
              <!--                  @keyup="userData.addressCo = userData.addressCo.toUpperCase()"-->
              <!--                ></v-text-field>-->
              <!--              </v-col>-->
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="userData.addressZip"
                  label="CAP"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[validators.required, validators.alphaDashValidator]"
                  placeholder="CAP"
                  :disabled="userData.type === SALONE"
                  @keyup="userData.addressZip = userData.addressZip.toUpperCase()"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="userData.addressProvince"
                  :items="province"
                  item-text="label"
                  item-value="value"
                  hide-details="auto"
                  label="Provincia"
                  outlined
                  dense
                  :rules="[validators.required]"
                  @change="userData.addressCity = ''"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="userData.addressCity"
                  :items="filterCityByProvince(userData.addressProvince)"
                  item-text="label"
                  item-value="value"
                  hide-details="auto"
                  label="Città"
                  outlined
                  dense
                  :rules="[validators.required]"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="userData.addressNote"
                  label="Altre informazioni di consegna (Opzionale)"
                  outlined
                  dense
                  hide-details="auto"
                  placeholder="Altre informazioni di consegna (Opzionale)"
                  @keyup="userData.addressStreet = userData.addressStreet.toUpperCase()"
                />
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-space-between">
            <v-btn
              outlined
              @click="handleStepValidation(2, false)"
            >
              Precedente
            </v-btn>
            <v-btn
              color="primary"
              @click="handleStepsProgress(2)"
            >
              Prossimo
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Stepper Content: Step 3 -->
        <v-stepper-content step="3">
          <v-form
            ref="step3Form"
            class="pb-6 pt-2 multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="userData.email"
                  label="Email"
                  outlined
                  dense
                  hide-details="auto"
                  type="email"
                  :rules="[validators.required, validators.emailValidator]"
                  placeholder="Email"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="userData.confirmEmail"
                  label="Conferma Email"
                  outlined
                  dense
                  hide-details="auto"
                  type="email"
                  :rules="[validators.required, validators.confirmedValidator(userData.confirmEmail,userData.email)]"
                  placeholder="Conferma Email"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="userData.password"
                  label="Password"
                  outlined
                  dense
                  hide-details="auto"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :rules="[validators.required, validators.passwordValidator]"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                  placeholder="Password"
                  hint="Il campo deve contenere un carattere in maiuscolo, uno in minuscolo, un numero, un carattere speciale fra questi !@#$%&*"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="userData.confirmPassword"
                  label="Conferma Password"
                  outlined
                  dense
                  hide-details="auto"
                  type="password"
                  :rules="[validators.required, validators.confirmedValidator(userData.confirmPassword,userData.password)]"
                  placeholder="Conferma Password"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="d-flex"
              >
                <div>
                  <v-checkbox
                    v-model="userData.privacy"
                    hide-details
                    :rules="[!!userData.privacy]"
                    class="mt-0 pt-0"
                  />
                </div>
                <div class="mr-auto">
                  Dichiaro di aver preso visione<a
                    href="https://ghdapp.blob.core.windows.net/support/privacy/Informativa%20portale%20assistenza%20clienti-%233297398-v2.pdf"
                    target="_blank"
                  > dell'informativa privacy.</a>
                </div>
              </v-col>
            </v-row>
          </v-form>

          <!-- Action Buttons -->
          <div
            class=" d-flex justify-space-between"
          >
            <v-btn
              outlined
              @click="handleStepValidation(3, false)"
            >
              Precedente
            </v-btn>
            <v-btn
              color="primary"
              @click="handleStepsProgress(3, true, true)"
            >
              Registrati
            </v-btn>
          </div>
          <v-snackbar
            v-model="isFormSubmittedSnackbarVisible"
            :color="snackbarData.color"
          >
            {{ snackbarData.message }}
          </v-snackbar>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { mdiEyeOutline, mdiEyeOffOutline, mdiMagnify } from '@mdi/js'

import {
  alphaDashValidator,
  alphaSlashValidator,
  alphaValidator,
  alphaWithSpacesValidator,
  between,
  confirmedValidator,
  emailValidator,
  integerValidator,
  lengthValidator,
  passwordValidator, pIvaValidator,
  required,
  maxlenght,
} from '@core/utils/validation'
import themeConfig from '@themeConfig'
import Vue from 'vue'
import registrationsService from '@/services/registrationsService'
import store from '@/store'
import router from '@/router'
import assistanceService from '@/services/assistanceService'

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const PRIVATO = 'PRIVATO'
    const SALONE = 'SALONE'
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)

    const {
      userData,
      province,
      comuni,

      generateProvincia,
      generateComune,
      filterCityByProvince,
      generateFiscalCode,

      fetchAddresses,
      getAddresses,
    } = registrationsService()

    userData.value.type = props.type.toUpperCase()

    generateProvincia()
    generateComune()

    const sogCodes = ref([])
    const isDisabledPIvaField = ref(false)
    const addresses = computed(() => getAddresses())
    const addressesSearched = ref(false)

    watch(() => userData.value.vat, newValue => {
      if (!newValue) {
        userData.value.codiceSoggetto = ''
        store.commit('user/setAddresses', [])
      }
    })

    const findAddresses = () => {
      if (userData.value.vat.length >= 11 || userData.value.vat.length === 5) {
        fetchAddresses().then(() => {
          addressesSearched.value = true
        })
      }
    }

    const selectedAddress = ref(null)

    const addressIsSelected = ref(false)

    // aggiungo index all'array per avere una chiave univoca nell'array
    const selectAddressCard = (clickedAddress, index) => {
      if (clickedAddress.available) {
        selectedAddress.value = clickedAddress
        selectedAddress.value.index = index

        // Assegno l'indirizzo selezionato per precompliare lo step successivo
        userData.value.businessName = clickedAddress.RagioneSociale
        userData.value.addressFirstName = clickedAddress.RagioneSociale
        userData.value.addressLastName = ''
        userData.value.codiceSoggetto = clickedAddress.CodiceSoggetto
        userData.value.addressCo = ''
        userData.value.addressStreet = clickedAddress.Indirizzo
        userData.value.addressNumber = ''
        userData.value.addressZip = clickedAddress.Cap
        userData.value.addressProvince = province.value.find(el => el.label === clickedAddress.Provincia.toUpperCase()) ? province.value.find(el => el.label === clickedAddress.Provincia.toUpperCase()).value : clickedAddress.Provincia
        userData.value.addressCity = clickedAddress.Localita

        addressIsSelected.value = true
      }
    }

    watch(menu, val => {
      // eslint-disable-next-line no-return-assign,no-unused-expressions
      val && setTimeout(() => (picker.value.activePicker = 'YEAR'))
    })

    const save = d => {
      menuref.value.save(d)
    }

    // Stepper's active step
    const activeStep = ref(1)

    // Form Submitted Snackbar
    const isFormSubmittedSnackbarVisible = ref(false)

    const snackbarData = ref({})

    const resolveBackgroundAddress = available => ({
      color: available ? '#ffffff' : '#e3e5e8',
    })

    const saveUser = () => {
      const userDataToChange = userData.value

      const sendData = {
        tipo: userDataToChange.type,
        codiceSoggetto: userDataToChange.codiceSoggetto,

        // Step 1
        // Privati
        nome: userDataToChange.firstName,
        cognome: userDataToChange.lastName,
        sesso: userDataToChange.gender,
        datanascita: userDataToChange.birthday,
        provincianascita: userDataToChange.province,
        comunenascita: userDataToChange.city,
        codicefiscale: userDataToChange.fiscalCode,
        telefono: userDataToChange.telephone,

        // Saloni
        partitaiva: userDataToChange.vat,
        fullname: userDataToChange.businessName,

        // Step 2
        indirizzo: [{
          presso: userDataToChange.addressCo,
          nome: userDataToChange.addressFirstName,
          cognome: userDataToChange.addressLastName,
          via: userDataToChange.addressStreet,
          civico: userDataToChange.addressNumber,
          cap: userDataToChange.addressZip,
          citta: userDataToChange.addressCity,
          provincia: userDataToChange.addressProvince,
          telefono: userDataToChange.telephone,
          note: userDataToChange.addressNote,
          predefinito: true,
        }],

        // Step 3
        email: userDataToChange.email,
        email_confirmation: userDataToChange.confirmEmail,
        password: userDataToChange.password,
        password_confirmation: userDataToChange.confirmPassword,

        privacy: userDataToChange.privacy,
      }

      store.dispatch('user/storeUser', sendData)
        .then(response => {
          snackbarData.value.message = response.data.message
          snackbarData.value.color = 'success'
          isFormSubmittedSnackbarVisible.value = true

          localStorage.setItem('email_verification_account', sendData.email)

          router.push({ name: 'verification' })
        }).catch(error => {
          const key = Object.keys(error.response.data.data)[0]
          // eslint-disable-next-line prefer-destructuring
          snackbarData.value.message = error.response.data.data[key][0]
          snackbarData.value.color = 'error'
          isFormSubmittedSnackbarVisible.value = true
        })
    }

    // Template refs
    const step1Form = ref(null)
    const step2Form = ref(null)
    const step3Form = ref(null)

    // Step Validation (for `rules` prop of `v-stepper-step`)
    // It will be updated when step is validated
    const stepValidationResult = ref({
      1: true,
      2: true,
      3: true,
    })

    // messaggi che devono apparire per ogni step, ogni chiave identifica il primo secondo e terzo step.
    const messageEachStep = [
      {
        headerMessage: 'Informazioni generali',
        subHeaderMessage: '',
      },
      {
        headerMessage: userData.value.type === SALONE ? 'Il tuo indirizzo' : 'Inserisci il tuo indirizzo',
        subHeaderMessage: userData.value.type === SALONE ? '' : 'Inserisci l\'indirizzo per la riconsegna del tuo prodotto',
      },
      {
        headerMessage: 'Inserisci i tuoi dati di accesso',
        subHeaderMessage: '',
      },
    ]

    const changeHeadersTextMessages = () => {
      const { headerMessage } = messageEachStep[activeStep.value - 1]
      const { subHeaderMessage } = messageEachStep[activeStep.value - 1]

      store.commit('assistance/setHeaderTextMessages', headerMessage)
      store.commit('assistance/setSubHeaderTextMessages', subHeaderMessage)
    }

    // Step Validation
    const handleStepValidation = (step, navigateNext = true, isFinalStep = false) => {
      // Get Form templateRef of Step from argument
      const templateRef = (() => {
        if (step === 1) return step1Form
        if (step === 2) return step2Form
        if (step === 3) return step3Form

        return null
      })()

      // If no validate step templateRef is found just return
      if (!templateRef) return

      // If step is valid proceed to the next step
      // ho aggiunto questo controllo per permettere lo step back senza dover prima validare i campi inseriti
      const isStepValid = navigateNext ? templateRef.value.validate() : true

      // Assign to stepValidationResult
      stepValidationResult.value[step] = isStepValid

      if (isStepValid) {
        if (navigateNext && !isFinalStep) activeStep.value += 1
        else if (!navigateNext) activeStep.value -= 1
        changeHeadersTextMessages()

        // Show Snackbar

        if (isFinalStep) {
          // ? Some Ajax request which saves form data in database
          saveUser()
        }
      }
    }

    // Gestico funzioni custom nei vari step
    const handleStepsProgress = step => {
      selectedAddress.value = null
      if (step === 1) {
        if (userData.value.businessName === '' && userData.value.type === SALONE) {
          Vue.$toast('Selezionare uno fra gli indirizzi disponibili', {
            timeout: 3000,
          })
        } else {
          handleStepValidation(step)
        }
      } else if (step === 2) {
        if (userData.value.addressStreet.includes(userData.value.addressNumber)) {
          userData.value.addressStreet = userData.value.addressStreet.replace(userData.value.addressNumber, '').trim()
        }

        handleStepValidation(step)
      } else if (step === 3) {
        handleStepValidation(step, true, true)
      }
    }

    const isPasswordVisible = ref(false)

    watch(userData.value, () => {
      generateFiscalCode()
    })

    const {
      getSubHeaderText,
    } = assistanceService()

    return {
      PRIVATO,
      SALONE,

      sogCodes,

      date,
      menu,
      picker,
      menuref,
      activeStep,
      userData,
      province,
      comuni,
      selectedAddress,
      isPasswordVisible,
      isDisabledPIvaField,
      addresses,

      save,
      generateProvincia,
      generateComune,
      filterCityByProvince,
      generateFiscalCode,
      fetchAddresses,
      getSubHeaderText,

      findAddresses,
      selectAddressCard,
      handleStepsProgress,
      addressesSearched,

      // Field Validators
      validators: {
        required,
        alphaDashValidator,
        emailValidator,
        passwordValidator,
        confirmedValidator,
        alphaValidator,
        alphaWithSpacesValidator,
        integerValidator,
        lengthValidator,
        pIvaValidator,
        alphaSlashValidator,
        between,
        maxlenght,
      },

      resolveBackgroundAddress,

      // Step Validations
      stepValidationResult,
      handleStepValidation,
      changeHeadersTextMessages,

      // Template Refs
      step1Form,
      step2Form,
      step3Form,

      // Snackbar
      isFormSubmittedSnackbarVisible,
      snackbarData,

      theme: themeConfig,
      addressIsSelected,

      mdiMagnify,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>
